import axios, { AxiosError } from 'axios'
import { API_URL } from '@/shared/constants'
import { HTTPResponse, IHTTPErrorResponse, IHTTPSuccessResponse } from '@/types/http.types'
import { IConfirmEmailResponse, IInitResponse } from '@/components/Auth/auth.types'
import userApi from '@/components/User/user.api'
import { handleHttpError, handleHttpResponse } from '@/shared/http'

type Contact = ({ email: string } | { phone: string }) & { password?: string }
const invalidEmailResp: IHTTPErrorResponse = {
  message: '',
  status: 'error',
  body: { email: 'Проверьте ваш e-mail адрес' },
}

/** Send confirmation code to specified contact */
const submitContact = async (
  contact: Contact & { referalCode?: string; captchaToken?: string; appToken?: string }
): Promise<HTTPResponse> => {
  return axios
    .post(API_URL + '/api/auth/login/', contact)
    .then(handleHttpResponse)
    .catch((err: AxiosError) => {
      if (axios.isAxiosError(err)) {
        const errObject = JSON.parse(JSON.stringify(err))

        // @ts-ignore
        if (+err.code === 400 || +errObject.status === 400) {
          return invalidEmailResp
        }
      }

      return handleHttpError(err as AxiosError)
    })
}

/** Login with confirmation code */
const confirmContact = async (contact: Contact, code: string): Promise<HTTPResponse<IConfirmEmailResponse>> => {
  return axios
    .post(API_URL + '/api/auth/confirm/', { ...contact, otp: code })
    .then((resp) => {
      resp.data.accessExpires *= 1000

      return handleHttpResponse(resp)
    })
    .catch((err: AxiosError) => {
      if (err.response.status === 401) {
        return { status: 'error' as const, message: 'Код не подходит.\n\nПроверьте его внимательно и введите еще раз.' }
      }

      return handleHttpError(err)
    })
}

/** Login with the confirmation code and get the user info */
const confirmContactWithInitDataInit = async (contact: Contact, code: string) => {
  const confirmResp = await confirmContact(contact, code)
  if (confirmResp.status === 'error') return confirmResp

  const tokens = confirmResp.body
  const user = await userApi.getSelfInfo(tokens.access)

  return { status: 'success', body: { user, tokens } } as IHTTPSuccessResponse<IInitResponse>
}

const authApi = { submitContact, confirmContactWithInitDataInit }
export default authApi
