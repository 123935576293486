import { Buffer } from 'buffer'

const generateAppKey = (): string => {
  const ts = new Date().getTime()
  const divided = Math.round(ts / 12)
  const transformed = divided.toString(8)

  return Buffer.from(transformed, 'utf-8').toString('base64')
}

const authUtils = { generateAppKey }
export default authUtils
