import { useEffect, useRef, useState } from 'react'
import authApi from '@/components/Auth/auth.api'
import { SUPPORT_MAIL } from '@/shared/constants'
import authUtils from '@/components/Auth/auth.utils'

const useAuthConfirm = (
  contact: string,
  contactType: number,
  password?: string,
  submitCodeFn: typeof authApi.submitContact = authApi.submitContact
) => {
  const intervalRef = useRef(null)

  const [isResendBlocked, setIsResendBlocked] = useState(false)
  const [helpPanelOpen, setHelpPanelOpen] = useState(false)

  const [secondsRemaining, setSecondsRemaining] = useState(30)
  const [resendAvailable, setResendAvailable] = useState(true)

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [])

  const toggleHelpPanel = () => {
    setHelpPanelOpen((prev) => !prev)
  }

  const resendCode = () => {
    const payload: any = contactType === 0 ? { email: contact } : { phone: contact }
    if (password) payload.password = password

    submitCodeFn({ ...payload, appToken: authUtils.generateAppKey() }).then(() => {
      setHelpPanelOpen(false)
    })
    setResendAvailable(false)

    if (intervalRef.current) clearInterval(intervalRef.current)

    setIsResendBlocked(true)
    intervalRef.current = setInterval(() => {
      setSecondsRemaining((prev) => {
        if (prev === 1) {
          clearInterval(intervalRef.current)
          setIsResendBlocked(false)
          return 30
        }

        return prev - 1
      })
    }, 1000)
  }

  return {
    helpPanelOpen,
    toggleHelpPanel,
    resendCode,
    secondsRemaining,
    isResendBlocked,
    handleSupportHelp,
    resendAvailable,
    setResendAvailable,
  }
}

export const handleSupportHelp = (subject = 'Не приходит одноразовый пароль') => {
  const link = document.createElement('a')
  link.href = 'mailto:' + SUPPORT_MAIL + '?subject=' + subject
  link.click()
}

export default useAuthConfirm
